@import "Variables";

.container {
    padding-left: 2em;
    padding-top: 2em;
}

.card {
    border: $border;
    box-shadow: 5px 5px 5px gainsboro;
    background-color: white;
    border-radius: 0.5em;

    > * {
        padding: 1em;
    }
}

.header {
    border-bottom: 1px solid lightgray;
}


@media screen and (max-width: $wide-screen - 1) {
    .filler {
        height: $linkSize;
        width: 100%;
    }
}
