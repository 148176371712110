@import "Variables";

.MobileMenu {
    display: none;
    flex-direction: row;
    border-top: $border;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $backgroundColor;

    .link {
        border-right: $border;
    }
}

.TopMenu {
    display: none;
    flex-direction: row;

    .link {
        border-right: $border;
    }
}
.link {
    height: $linkSize;
    width: $linkSize;
    color: black;
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;

        .divider {
            flex-grow: 1;
        }

        .icon {
            width: 3em;
            justify-content: center;
        }

        p {
            margin: 0;
        }
    }

    svg {
        color: gray;
    }
}

.active {
    background-color: $activeLinkColor;
    svg {
        color: $themeBlue;
    }
}

.link:hover {
    background-color: $hoverLinkBackgroundColor;
    svg {
        color: $hoverLinkForegroundColor;
    }
}

@media screen and (max-width: $wide-screen - 1) {
    .MobileMenu {
        display: flex;
    }
}
@media screen and (min-width: $wide-screen) {
    .TopMenu {
        display: flex;
    }
}
