$wide-screen: 600px;

$activeLinkColor: Gainsboro;
$hoverLinkBackgroundColor: Silver;
$hoverLinkForegroundColor: #000099;
$themeBlue: #103a7d;

$border: 1px solid $hoverLinkBackgroundColor;

$backgroundColor: #F8F8F8;

$linkSize: 6em;
