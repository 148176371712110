.subLine {
    margin-bottom: 0;
}

.projects {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .project {
        margin: 2em;
        width: 20em;

        .body {
            :first-child {
                margin-top: 0;
            }
            :last-child {
                margin-bottom: 0;
            }
        }
    }
}
