@import "Variables";

.App {

    .AppContainer {
        align-self: center;
    }

    .header {
        display: flex;
        border-bottom: $border;
        align-items: center;
        padding-left: 2em;
    }

    .LogoContainer {
        display: flex;
        margin-right: 2em;

        .logo {
            height: 4em;
        }
    }
}
@media screen and (max-width: $wide-screen - 1) {

    .AppContainer {
        margin: 1em;
    }
}
